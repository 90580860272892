<template>
  <b-list-group>
    <b-list-group-item
        class="flex-column align-items-start"
        v-for="item in notifications"
        :key="item.id"
        style="margin-bottom: 10px;border-top-width: 1px"
        v-if="item.visible"
    >
      <div @click="setAsRead(item.id)"
           style="position: absolute; top: 5px; right: 10px;cursor: pointer;"
           :title="item.read ? 'Wiadomość przeczytana' : 'Oznacz jako przeczytane'"
      >
        <feather-icon v-if="!item.read" icon="CircleIcon"/>
        <feather-icon v-if="item.read" icon="CheckCircleIcon"/>
      </div>
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mt-2">
          {{ item.title }}
        </h5>
        <small class="text-secondary mt-2" v-if="isOld(item.publish_at) == false">{{ item.publish_at }}</small>
      </div>
      <div class="mb-1 d-flex w-100 justify-content-between">
        <small class="text-secondary">{{ item.course_name }}</small>
      </div>
      <b-card-text class="mb-2" v-html="item.content">
        {{ item.content }}
      </b-card-text>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import {BCard, BListGroup, BListGroupItem, BCardText, BButton} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BButton,
  },
  data() {
    return {
      notifications: [],
      introImg: require('@/assets/images/chemmaster/intro.png'),
    }
  },
  created() {
    this.fetchNotifications()
  },
  watch: {
    '$route.query.id': {
      handler: function(selectedId) {
        this.fetchNotifications()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    isOld(date) {
      // current date
      const current_date = new Date();

      // need to remake date string into mm/dd/yyyy format
      const notification_arr = date.split('.');
      const notification_date = new Date(notification_arr[1] + "/" + notification_arr[0] + "/" + notification_arr[2]);

      // if difference in dates is more than 30 days - return true
      const diffTime = current_date - notification_date;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if(diffDays > 30)
        return true;
      return false;
    },
    fetchNotifications() {
      let selectedId = this.$route.query.id;
      this.$http.get(`https://api.${this.$store.state.domain}/api/my/notifications?perPage=1000`).then(response => {
        this.notifications = JSON.parse(JSON.stringify(response.data.notifications));

        this.notifications.map(function (item){
          if(typeof selectedId === 'undefined') {
            item.visible = true;
          }
          else if(item.id === parseInt(selectedId)) {
            item.visible = true;
          }
          else {
            item.visible = false;
          }

          return item;
        })
      })
    },
    setAsRead(id) {
      this.$http.post(`https://api.${this.$store.state.domain}/api/my/notifications/setAsRead`, { notification_id: id }).then(response => {
        this.fetchNotifications()
      })
    },
  }
}
</script>

<style>
  img{
    max-width: 100%;
    height: auto;
  }
</style>
